import React, { Fragment, Suspense, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import store from '../store'
import '../styles/main.css'
import Header from './Header'
import Footer from './Footer'

const Home = lazy(() => import('../pages/Home'))

function AppRouter () {
  useEffect(() => {
    store.initStore()
  })

  return (
    <>
    <div className='min-h-screen'>
      <Header />
        <div className='flex flex-col items-center'>
          <Router>
            <Routes>
              <Route path="/" element={
                <Suspense fallback={<>Loading...</>}>
                  <Home />
                </Suspense>
              } />
            </Routes>
          </Router>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AppRouter
