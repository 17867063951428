import React from 'react'

export default function Footer () {
  return (
    <div className='bg-dark text-light p-5 text-center gap-y-10 flex flex-col'>
      <h2 className='sm:text-7xl text-3xl font-Noto'>Thomas<br/>Grenmoor</h2>
      <a target='_blank' href='https://www.instagram.com/thomas_grenmoor/' className='text-xl hover:shadow-[inset_0_-3px_0_#CA8B21] w-fit mx-auto py-3' rel="noreferrer">Instagram</a>
      <div className='flex flex-col sm:flex-row justify-center sm:justify-between gap-2.5 text-xs'>
        <div className='flex-1 text-center sm:text-left'>©Thomas Grenmoor {new Date().getFullYear()}</div>
        <div className='flex-1 text-center'>Bonne lecture</div>
        <div className='flex-1 text-center sm:text-right'>Développé par la Taverne des Soiffards</div>
      </div>
    </div>
  )
}
