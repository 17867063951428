import React, { useEffect, useState } from 'react'
import IcoBook from '../assets/icons/book'

export default function Header () {
  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`fixed left-0 top-0 right-0 h-12 items-center flex justify-center px-5 transition-all z-30 text-dark font-semibold ${scrolling ? 'bg-light shadow-md' : 'bg-transparent'}`}>
      <nav className='font-Noto flex justify-between max-w-desktop w-full text-sm lg:text-base'>
        <a href="https://amzn.eu/d/imxIqmF" target='_blank' className='md:flex hidden items-center gap-x-2.5 hover:shadow-[inset_0_-3px_0_#CA8B21] p-3 rounded-b-sm hover:rounded-b-none bg-light' rel="noreferrer">
          <div className='h-6 w-6'><IcoBook color={'#2d1e25'} /></div>
          <div>Obtenir Le livre</div>
        </a>
        <div className='md:flex hidden'>
          <a href="#page-1" className='hover:shadow-[inset_0_-3px_0_#CA8B21] p-3'>Les Runes Ancestrales</a>
          <a href="#page-3" className='hover:shadow-[inset_0_-3px_0_#CA8B21] p-3'>Thomas Grenmoor</a>
          <a href="#page-4" className='hover:shadow-[inset_0_-3px_0_#CA8B21] p-3'>Progression</a>
          <a href="#page-5" className='hover:shadow-[inset_0_-3px_0_#CA8B21] p-3'>Actualités</a>
        </div>
        <h1 className={`text-xl w-full block md:hidden font-Charmonman ${scrolling ? 'text-center font-Noto' : 'text-left'}`}>Thomas Grenmoor</h1>
      </nav>
    </div>
  )
}
